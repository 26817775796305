import Index from "views/Index.js";
// import Profile from "views/sidebar/Profile.js";
// import Maps from "views/sidebar/Maps.js";
import Register from "views/sidebar/Register.js";
import Login from "views/sidebar/Login.js";
// import Tables from "views/sidebar/Tables.js";
// import Icons from "views/sidebar/Icons.js";
import Course from "views/sidebar/Course.js";
import Banner from "views/sidebar/Banner";
import Event from "views/sidebar/Event";
import Expert from "views/sidebar/Expert";
import Article from "views/sidebar/Article";
import EventCategory from "./views/sidebar/EventCategory";
import CourseCategory from "./views/sidebar/CourseCategory";
import ExpertCategory from "./views/sidebar/ExpertCategory";
import Schedule from "views/sidebar/Schedule";
import RisetCategory from "views/sidebar/RisetCategory";
import Riset from "views/sidebar/Riset";
import OrderHistory from "views/sidebar/OrderHistory";
import EventRegister from "views/sidebar/EventRegister";

const routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: <Banner/>,
    layout: "/admin",
  },
  {
    path: "/banner",
    name: "Banner",
    icon: "ni ni-archive-2 text-green",
    component: <Banner/>,
    layout: "/admin",
  },
  {
    path: "/course-category",
    name: "Course Category",
    icon: "fa-solid fa-expand text-default",
    component: <CourseCategory />,
    layout: "/admin",
  },
  {
    path: "/course",
    name: "Course",
    icon: "ni ni-single-copy-04 text-orange",
    component: <Course/>,
    layout: "/admin",
  },
  {
    path: "/event-category",
    name: "Event Category",
    icon: "ni ni-fat-add text-info",
    component: <EventCategory/>,
    layout: "/admin",
  },
  {
    path: "/event",
    name: "Event",
    icon: "ni ni-map-big text-primary",
    component: <Event/>,
    layout: "/admin",
  },
  {
    path: "/event-register",
    name: "Event Register",
    icon: "ni ni-map-big text-primary",
    component: <EventRegister/>,
    layout: "/admin",
  },
  {
    path: "/expert-category",
    name: "Expert Category",
    icon: "fa-solid fa-share-from-square text-default",
    component: <ExpertCategory/>,
    layout: "/admin",
  },
  {
    path: "/expert",
    name: "Expert",
    icon: "ni ni-paper-diploma text-yellow",
    component: <Expert/>,
    layout: "/admin",
  },
  {
    path: "/schedule",
    name: "Schedule",
    icon: "ni ni-paper-diploma text-yellow",
    component: <Schedule />,
    layout: "/admin"
  },
  {
    path: "/riset-category",
    name: "Riset Category",
    icon: "fa fa-book text-yellow",
    component: <RisetCategory />,
    layout: "/admin"
  },
  {
    path: "/riset",
    name: "Riset",
    icon: "fa fa-book text-yellow",
    component: <Riset />,
    layout: "/admin"
  },
  {
    path: "/order-history",
    name: "Order History",
    icon: "fa fa-dollar text-yellow",
    component: <OrderHistory />,
    layout: "/admin"
  },
  {
    path: "/article",
    name: "Article",
    icon: "ni ni-paper-diploma text-pink",
    component: <Article/>,
    layout: "/admin",
  },
    // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: <Icons />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: <Maps />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: <Profile />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: <Tables />,
  //   layout: "/admin",
  // },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login/>,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: <Register/>,
    layout: "/auth",
  }
];
export default routes;
